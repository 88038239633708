import React from 'react'
import { Link } from 'gatsby'
import { ThemeSwitch } from '../theme-switch'
import { GitHubIcon } from '../social-share/github-icon'
import { rhythm } from '../../utils/typography'

import './index.scss'

export const Top = ({ title, location, rootPath }) => {
  const isRoot = location.pathname === rootPath
  return (
    <div className="top" >
      <div className="container" style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(40),

      }}>
        <Link to={`/`} className="link">
          {title}
        </Link>
        <div className="tool-bar">
          <ThemeSwitch className="theme-switch" />
        </div>
      </div>
    </div>
  )
}
